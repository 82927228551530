export const COMMERCIAL_PARTNERSHIP_EVENT = 'CommercialPartnershipEvent';
export const DISCOVERY_EVENT = 'DiscoveryEvent';
export const FEATURED_SET_EVENT = 'FeaturedSetEvent';
export const PRIVATE_DISCOVERY_EVENT = 'PrivateDiscoveryEvent';
export const EVENT_FORMAT_EXPERIMENT = 'EventFormatExperiment';

// Event Owner Types
export const O_AND_O = 'o_and_o';
export const CURATOR_COMMERCIAL = 'curator_commercial';
export const CURATOR_NONCOMMERCIAL = 'curator_noncommercial';
export const CURATOR_OWNER_COMMERCIAL = 'curator_owner_commercial_event';
export const CURATOR_OWNER_NONCOMMERCIAL = 'curator_owner_noncommercial_event';

export const REMOVE_VENUE_SENTINEL = -1;

export const eventAttendeeOccasions = {
  birthday: 'Birthday',
  anniversary: 'Anniversary',
  date_night: 'Date night',
  other: 'Other',
};

export const merchandisingPropertyGroupNames = [
  'Genre Information',
  'Seating Information',
  'Vibe Description',
  'Other',
];

// Max tickets per event purchasable by one user
export const MAX_TICKETS = 50;

export const getNoNeighborhoodYetText = (intl: any) =>
  intl.formatMessage({
    id: 'admin.offerCreate.noNeighborhoodYet',
  });

export const EventStatus = {
  NONE: 0,
  CANCELLED: 1,
  SOLDOUT: 2,
  PAUSED: 3,
};
